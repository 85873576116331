<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRootStore } from "@/store/root";

const rootStore = useRootStore();
const { notify } = storeToRefs(rootStore);

const textColourMap = {
  success: "text-primary",
  danger: "text-danger",
  warning: "text-warning",
};
const borderColourMap = {
  success: "border-primary",
  danger: "border-danger",
  warning: "border-warning",
};

const notifyIconMap = {
  success: "Fa6SolidCircleCheck",
  danger: "Fa6SolidCircleXmark",
  warning: "Fa6SolidCircleExclamation",
};

const textColour = computed(() => textColourMap[notify.value.type] || "text-primary");
const borderColour = computed(() => borderColourMap[notify.value.type] || "border-primary");
const notifyIcon = computed(() => notifyIconMap[notify.value.type] || "Fa6SolidCircleCheck");
const closeNotify = () => rootStore.toggleShowNotify({ active: false });
</script>

<template>
  <div aria-live="assertive" class="base-notify fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition name="notify-slide-fade">
        <div
          v-if="notify.active"
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden border-1 border-solid"
          :class="borderColour"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <BaseIcon :icon="notifyIcon" :class="['w-5 h-5', textColour]" />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium" :class="textColour">{{ notify.title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ notify.desc }}</p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <BaseIcon @click="closeNotify" icon="Fa6SolidXmark" class="w-4 h-4 cursor-pointer transition-all hover:rotate-180" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.base-notify {
  z-index: 100 !important;
}

</style>
